import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Front from './front/Front';
import Admin from './admin/Admin';
import GlobalContextProvider from './context/GlobalContext';
import 'react-toastify/dist/ReactToastify.css';
import { ADMIN_URL } from './admin/admin-config';
import AdminLogin from './admin/Login';
const url = process.env.REACT_APP_API_HOST || '';

function App() {
  return (
    <GlobalContextProvider url={`//${url || window.location.hostname}`}>
      <BrowserRouter>
        <Routes>
          <Route path='*' Component={Front} />
          <Route path={`${ADMIN_URL}/login`} Component={AdminLogin} />
          <Route path={`${ADMIN_URL}/*`} Component={Admin} />
        </Routes>
      </BrowserRouter>
    </GlobalContextProvider>
  );
}

export default App;
