import { createContext, useContext } from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import ElloraContextProvider from './ElloraContext';
import { setContext } from '@apollo/client/link/context';

type GlobalContextType = {
  url: string;
  currency: string;
};
type ContextType = {
  children: any;
  url: string;
};
const GlobalContext = createContext<GlobalContextType>({} as GlobalContextType);
export const useGlobalContext = () => useContext(GlobalContext);
function GlobalContextProvider({ children, url }: ContextType) {
  const httpLink = createHttpLink({
    uri: `${url}/graphql`,
  });
  const authLink = setContext((_, { headers }) => {
    const token = sessionStorage.getItem('access_token') || '';
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  return (
    <GlobalContext.Provider value={{ url, currency: '$' }}>
      <ApolloProvider client={client}>
        <ElloraContextProvider>{children}</ElloraContextProvider>
      </ApolloProvider>
    </GlobalContext.Provider>
  );
}

export default GlobalContextProvider;
