import { Dropdown } from 'react-bootstrap';
import Logo from '../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
import { ADMIN_URL } from '../admin-config';
function Header() {
  const navigate = useNavigate();
  const Signout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate(`${ADMIN_URL}/login`);
  };
  return (
    <header id='page-topbar'>
      <div className='navbar-header'>
        <div className='d-flex'>
          <div className='navbar-brand-box'>
            <a href='/' className='logo logo-dark'>
              <span className='logo-sm'>
                <img src={Logo} alt='' height='44' />
              </span>
              <span className='logo-lg'>
                <img src={Logo} alt='' height='44' />
              </span>
            </a>

            <a href='/' className='logo logo-light'>
              <span className='logo-sm'>
                <img src={Logo} alt='' height='44' />
              </span>
              <span className='logo-lg'>
                <img src={Logo} alt='' height='44' />
              </span>
            </a>
          </div>
        </div>

        <Dropdown>
          <Dropdown.Toggle variant='success' id='dropdown-basic'>
            Admin
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as='button' onClick={Signout}>
              Signout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </header>
  );
}

export default Header;
