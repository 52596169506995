import { gql } from '@apollo/client';

export const CreateReview = gql`
  mutation AddReview(
    $product: ID
    $name: String
    $city: String
    $title: String
    $review: String
    $rating: Int
    $publishedAt: DateTime
    $rating_date: DateTime
  ) {
    createRating(
      data: {
        product: $product
        name: $name
        city: $city
        title: $title
        review: $review
        rating: $rating
        publishedAt: $publishedAt
        rating_date: $rating_date
      }
    ) {
      data {
        id
        attributes {
          city
          name
          rating_date
          title
          review
          rating
        }
      }
    }
  }
`;

export const RegisterUser = gql`
  mutation RegisterUser(
    $first_name: String
    $last_name: String
    $username: String!
    $email: String!
    $password: String!
  ) {
    register(
      input: {
        first_name: $first_name
        last_name: $last_name
        username: $username
        email: $email
        password: $password
      }
    ) {
      jwt
      user {
        id
        email
        username
        confirmed
        first_name
        last_name
      }
    }
  }
`;
export const LoginUser = gql`
  mutation LoginUser($email: String!, $password: String!) {
    login(input: { identifier: $email, password: $password, provider: "local" }) {
      jwt
      user {
        username
        id
        email
        first_name
        last_name
      }
    }
  }
`;

export const CreateOrder = gql`
  mutation CreateOrder(
    $order_id: Long
    $transaction_id: String
    $first_name: String
    $last_name: String
    $email: String
    $phone: String
    $company_name: String
    $address1: String
    $address2: String
    $country: String
    $state: String
    $city: String
    $zip_code: String
    $shipping_cost: Float
    $shipping_method: String
    $total_amount: Float
    $tax: Float
    $payment_method: String
    $status: ENUM_ORDER_STATUS
    $publishedAt: DateTime
    $userid: String
    $discount:Float
    $coupon:String
  ) {
    createOrder(
      data: {
        order_id: $order_id
        transaction_id: $transaction_id
        first_name: $first_name
        last_name: $last_name
        email: $email
        phone: $phone
        company_name: $company_name
        address1: $address1
        address2: $address2
        country: $country
        state: $state
        city: $city
        zip_code: $zip_code
        shipping_cost: $shipping_cost
        shipping_method: $shipping_method
        total_amount: $total_amount
        tax: $tax
        payment_method: $payment_method
        status: $status
        publishedAt: $publishedAt
        userid: $userid
        discount: $discount
        coupon: $coupon
      }
    ) {
      data {
        id
        attributes {
          order_id
          total_amount
          tax
          discount
        }
      }
    }
  }
`;

export const CreateOrderItem = gql`
  mutation CreateOrderItem($product: ID, $quentity: Int, $price: Float, $order: ID) {
    createOrderItem(
      data: { product: $product, quentity: $quentity, price: $price, order: $order }
    ) {
      data {
        id
      }
    }
  }
`;

export const CreateContact = gql`
  mutation CreateContact(
    $name: String
    $email: String
    $phone: String
    $company_name: String
    $country_name: String
    $message: String
  ) {
    createInquiry(
      data: {
        name: $name
        email: $email
        phone: $phone
        company_name: $company_name
        country_name: $country_name
        message: $message
      }
    ) {
      data {
        id
      }
    }
  }
`;
