import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { HomePage, getAllSetting } from '../queries/query';
import logo from '../assets/images/logo.png';
import { getAllCategoriesWithSubcategories } from '../queries/Category';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
declare const UniversalCookie: any
type ElloraContextType = {
  social: any;
  logo: any;
  categories: any;
  getCategoryIdBySlug: any;
  getSubCategoryIdBySlug: any;
  category: any;
  setCategory: any;
  subCategory: any;
  setSubCategory: any;
  product: any;
  setProduct: any;
  cart: any;
  addToCart: any;
  deleteFromCart: any;
  admin: any;
  setAdmin: any;
  homePage: any;
  setCart: any;
  cookie: any;
};
type ContextType = {
  children: any;
};
const ElloraContext = createContext<ElloraContextType>({} as ElloraContextType);
export const useElloraContext = () => useContext(ElloraContext);
function ElloraContextProvider({ children }: ContextType) {
  const [category, setCategory] = useState<any>();
  const [subCategory, setSubCategory] = useState<any>();
  const [product, setProduct] = useState<any>();
  const [social, setSocial] = useState();
  const [homePage, setHomePage] = useState<any>();
  const [categories, setCategories] = useState<any>();
  const [subCategories, setSubCategories] = useState<any>();
  const { data: setting } = useQuery(getAllSetting);
  const { data: home } = useQuery(HomePage);
  const { data, loading: categoryLoading } = useQuery(getAllCategoriesWithSubcategories);
  const [cart, setCart] = useState<any>({});
  const [admin, setAdmin] = useState<any>(JSON.parse(sessionStorage.getItem('admin') || '{}'));
  const [cookie, setCookie] = useState<any>()

  useEffect(() => {
    setCookie(new UniversalCookie(null, { path: "/", sameSite: true, expires: moment().add(1,"M").toDate() }))
  }, []);

  useEffect(() => {
    if (cookie) {
      if (cookie.get('cart') !== undefined) {
        setCart(cookie.get("cart"));
      }
    }
  }, [cookie]);
  useEffect(() => {
    if (setting?.socialMedia) {
      const attr = setting.socialMedia.data.attributes;
      setSocial({
        phone_number: attr.phone_number.phone_number,
        phone_number2: attr.phone_number2.phone_number,
        ...attr.social,
        banner_image: attr?.banner_image?.data?.attributes?.url,
        banner_image_id: attr?.banner_image?.data?.id,
      } as any);
    }
  }, [setting]);
  useEffect(() => {
    if (home?.home) {
      setHomePage({
        global_award_bg: home.home?.data?.attributes.global_award_bg.data?.id,
        number_of_product_in_slider: home.home?.data?.attributes.number_of_product_in_slider || 9,
        health_bg: home.home?.data?.attributes.health_bg.data?.id,
        global_gold_award_bg: home.home?.data?.attributes.global_gold_award_bg.data?.id,
        subscribe_section_bg: home.home?.data?.attributes.subscribe_section_bg.data?.id,
        global_award_bg_url: home.home?.data?.attributes.global_award_bg.data?.attributes?.url,
        health_bg_url: home.home?.data?.attributes.health_bg.data?.attributes?.url,
        global_gold_award_bg_url:
          home.home?.data?.attributes.global_gold_award_bg.data?.attributes?.url,
        subscribe_section_bg_url:
          home.home?.data?.attributes.subscribe_section_bg.data?.attributes?.url,
        moveable_text_title: home.home?.data?.attributes.moveable_text_title,
        moveable_text_subtitle: home.home?.data?.attributes.moveable_text_subtitle,
      });
    }
  }, [home]);

  useEffect(() => {
    if (!categoryLoading) {
      setCategories(data);
      if (data?.categories) {
        let _subcats = [] as any;
        data.categories.data.map((c: any) => {
          _subcats = [..._subcats, ...c.attributes.sub_categories?.data];
        });
        setSubCategories(_subcats);
      }
    }
  }, [data, categoryLoading]);

  const getSubCategoryIdBySlug = useCallback(
    (slug: string) => {
      if (subCategories) {
        const _subcategory = subCategories.filter((s: any) => s.attributes.slug === slug);
        return _subcategory.length ? _subcategory[0] : undefined;
      }
    },
    [subCategories],
  );
  const getCategoryIdBySlug = useCallback(
    (slug: string) => {
      if (categories?.categories) {
        const _category = categories.categories.data.filter((c: any) => c.attributes.slug === slug);
        return _category.length ? _category[0] : undefined;
      }
    },
    [categories],
  );

  const addToCart = (product: any, qty: number) => {
    const taxs = product.attributes.categories.data.map((pc: any) => pc.attributes.slug == "kitchenware" ? (product.attributes.price * 6.5) / 100 : 0)
    const tax = taxs.reduce((sum: number, a: number) => sum + a, 0)
    if (product.attributes.quantity >= qty) {
      const product_id = product.id
      const newCart = { ...cart };
      let message = 'Product added to cart.';
      if (product_id in newCart) {
        qty = newCart[product_id].qty + qty;
        if (product.attributes.quantity >= qty) {
          message = 'Product quantity updated.';
          newCart[product_id] = { product_id, qty, tax };
          cookie.set('cart', JSON.stringify(newCart));
          setCart(newCart);
          toast.success(message, { toastId: 'cart', updateId: 'cart' });
        } else {
          message = 'Product quantity limit exceeded';
          toast.error(message, { toastId: 'cart', updateId: 'cart' });
        }

      } else {
        newCart[product_id] = { product_id, qty, tax };
        cookie.set('cart', JSON.stringify(newCart));
        setCart(newCart);
        toast.success(message, { toastId: 'cart', updateId: 'cart' });
      }
      window.scrollTo(0, 0)
    } else {
      toast.error("Product quantity limit exceeded", { toastId: 'cart', updateId: 'cart' });
    }
  };
  const deleteFromCart = (product_id: number) => {
    const newCart = { ...cart };
    delete newCart[product_id];
    cookie.set('cart', JSON.stringify(newCart));
    setCart(newCart);
    toast.success('Product deleted from cart.', { toastId: 'cart', updateId: 'cart' });
  };
  return (
    <ElloraContext.Provider
      value={{
        social,
        logo,
        categories,
        getCategoryIdBySlug,
        getSubCategoryIdBySlug,
        category,
        setCategory,
        subCategory,
        setSubCategory,
        product,
        setProduct,
        cart,
        addToCart,
        deleteFromCart,
        admin,
        setAdmin,
        homePage,
        setCart,
        cookie
      }}
    >
      {children}
      <ToastContainer />
    </ElloraContext.Provider>
  );
}

export default ElloraContextProvider;
