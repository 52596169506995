import { gql } from '@apollo/client';
export const findCategoryFront = gql`
  {
    categories {
      data {
        id
        attributes {
          name
          slug
          short_content
          image {
            data {
              attributes {
                formats
                url
              }
            }
          }
        }
      }
    }
  }
`;
export const getAllCategoriesWithSubcategories = gql`
  {
    categories {
      data {
        id
        attributes {
          name
          slug
          banner_image {
            data {
              attributes {
                url
                formats
              }
            }
          }
          sub_categories {
            data {
              id
              attributes {
                name
                slug
                short_content
                image {
                  data {
                    attributes {
                      url
                      formats
                    }
                  }
                }
                banner_image {
                  data {
                    attributes {
                      url
                      formats
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const getCategoryById = (id: number) => gql`
{
  category(id:${id}){
    data{
      id
      attributes{
        name
        slug
        banner_image{
          data{
            attributes{
              url
              formats
            }
          }
        }
        sub_categories{
          data{
            id
            attributes{
              name
              slug,
              short_content
              image{
                data{
                  attributes{
                    url
                    formats
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
