import { ErrorMessage, Field, Form, Formik } from 'formik';
import payment from '../../assets/images/payment-method.png';
import { useElloraContext } from '../../context/ElloraContext';
import { useGlobalContext } from '../../context/GlobalContext';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ContactUsBG from "../../assets/images/olive-trees-517437_1280.webp"
const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Enter a valid email').required('Name is required'),
  phone: Yup.number().min(10).required('Phone number is required'),
});
const initialValue = {
  name: '',
  email: '',
  phone: '',
};
function Footer() {
  const { url } = useGlobalContext();
  const { social, logo, categories, homePage } = useElloraContext();
  const [createSubscriber] = useMutation(gql`
    mutation CreateSubscriber(
      $name: String
      $phone: String
      $email: String
      $publishedAt: DateTime
    ) {
      createSubscriber(
        data: { name: $name, phone: $phone, email: $email, publishedAt: $publishedAt }
      ) {
        data {
          id
        }
      }
    }
  `);
  const onSubmitSubscriber = (values: any, { resetForm }: any) => {
    createSubscriber({
      variables: { ...values, publishedAt: moment().format() },
    })
      .then((_res) => {
        toast.success('Thank you for subscribing us');
        resetForm();
      })
      .catch((err) => toast.error(err.message));
  };
  return (
    <>
      <div
        className='subscribe-section section position-relative pt-70 pb-150 pt-150 pt-md-60 pb-md-60 pt-sm-50 pb-sm-50 pt-xs-50 pb-xs-50 fix'
        style={{
          background: `url(${url}${homePage?.subscribe_section_bg_url})`,
        }}
      >
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-12' style={{ padding: '50px' }}></div>
          </div>
        </div>
      </div>
      <div className='subscribe-section section position-relative pt-70 pb-150 pt-150 pt-md-60 pb-md-60 pt-sm-50 pb-sm-50 pt-xs-50 pb-xs-50 fix'
      style={{
        background: `url(${ContactUsBG})`,
      }}>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-12'>
              <div className='subscribe-wrap'>
                <h3 style={{ fontSize: '34px' }}>Contact Us</h3>
                <Formik
                  initialValues={initialValue}
                  validationSchema={validationSchema}
                  onSubmit={onSubmitSubscriber}
                >
                  {() => (
                    <Form className='mc-form subscribe-form' style={{ maxWidth: '90%' }}>
                      <div style={{ width: '100%' }} className='mr-5'>
                        <Field type='text' name='name' placeholder='Name' /> <br />
                        <ErrorMessage name='name' component='div' className='error' />
                      </div>
                      <div style={{ width: '100%' }} className='mr-5'>
                        <Field type='email' name='email' placeholder='Email' />
                        <ErrorMessage name='email' component='div' className='error' />
                      </div>
                      <div style={{ width: '100%' }} className='mr-5'>
                        <Field type='text' name='phone' placeholder='Phone' />
                        <ErrorMessage name='phone' component='div' className='error' />
                      </div>
                      <div style={{ width: '50%' }} className='mr-5'>
                        <button type='submit'>submit</button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='service-section section pl-15 pr-15 pl-lg-30 pr-lg-30 pl-md-30 pr-md-30 mt-10'>
        <div className='service-container'>
          <div className='row ml-0 mr-0'>
            <div className='service col-xl-3 col-md-6 col-12'>
              <div>
                <i className='fa fa-get-pocket fa-2x' aria-hidden='true'></i>
              </div>
              <div className='content'>
                <h3>
                  <Link to='/contact'>Get in touch with us</Link>
                </h3>
              </div>
            </div>
            <div className='service col-xl-3 col-md-6 col-12'>
              <div>
                <a
                  href={`${url}/uploads/1616697829_Ellora_EVOO_Brochure_2021_23f774e9df.pdf`}
                  target='_blank'
                  download
                  rel='noreferrer'
                >
                  <i className='fa fa-download fa-2x' aria-hidden='true'></i>
                </a>
              </div>
              <div className='content'>
                <h3>
                  <a
                    href={`${url}/uploads/1616697829_Ellora_EVOO_Brochure_2021_23f774e9df.pdf`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Olive Oil Brochure
                  </a>
                </h3>
              </div>
              <div>
                <a
                  href={`${url}/uploads/1620509554_Ellora_Farms_Balsamic_Brochure_c5740eb5d4.pdf`}
                  target='_blank'
                  download
                  rel='noreferrer'
                >
                  <i className='fa fa-download fa-2x' aria-hidden='true'></i>
                </a>
              </div>
              <div className='content'>
                <h3>
                  <a
                    href={`${url}/uploads/1620509554_Ellora_Farms_Balsamic_Brochure_c5740eb5d4.pdf`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Balsamics Brochure
                  </a>
                </h3>
              </div>
            </div>
            <div className='service col-xl-3 col-md-6 col-12'>
              <div>
                <a
                  href={`${url}/uploads/1620353897_ELLORAFARMS_PRESENTATION_2021_f57f7619b4.pdf`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fa fa-newspaper-o fa-2x' aria-hidden='true'></i>
                </a>
              </div>
              <div className='content'>
                <h3>
                  <a
                    href={`${url}/uploads/1620353897_ELLORAFARMS_PRESENTATION_2021_f57f7619b4.pdf`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Corporate Presentation
                  </a>
                </h3>
              </div>
            </div>
            <div className='service col-xl-3 col-md-6 col-12'>
              <div>
                <i className='fa fa-globe fa-2x' aria-hidden='true'></i>
              </div>
              <div className='content'>
                <h3>
                  <a href='https://www.thegrindstone.us/' target='_blank' rel='noreferrer'>
                    www.tracetheorigin.com
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='footer-top-section section bg-dark'>
        <div className='container'>
          <div className='footer-widget-wrap row'>
            <div className='col mb-40'>
              <div className='footer-widget text-center'>
                <img src={logo} alt='ellora farm logo' style={{ height: '150px' }} />
                <p>{social?.address}</p>
                <p>
                  <a href={`tel:${social?.phone_number}`}>{social?.phone_number}</a>
                  <a href={`tel:${social?.phone_number2}`}>{social?.phone_number2}</a>
                </p>
                <p>
                  <a href={`mail:${social?.email}`}>{social?.email}</a>
                  <br /> <a href={window.location.host}>Shop Ellora Farms</a>
                </p>
              </div>
            </div>
            <div className='col mb-40'>
              <div className='footer-widget'>
                <h3 className='title'>Quick Link</h3>
                <ul>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  <li>
                    <Link to='/about'>About Us</Link>
                  </li>
                  {categories?.categories &&
                    categories.categories.data.map((category: any) => {
                      return (
                        <li key={`footer_${category.id}`}>
                          <Link to={category.attributes.slug}>{category.attributes.name}</Link>
                        </li>
                      );
                    })}
                  <li>
                    <Link to='/wholesale'>Wholesale</Link>
                  </li>
                  <li>
                    <Link to='/blogs'>Blogs</Link>
                  </li>
                  <div className='col-xs-12 col-md-3 col-sm-3 full_width footer_padding_right animated fadeInUp delay-1000'></div>
                </ul>
              </div>
            </div>
            <div className='col mb-40'>
              <div className='footer-widget'>
                <h3 className='title'>Information</h3>
                <ul>
                  <li>
                    <Link to='/contact'>Contact</Link>
                  </li>
                  <li>
                    <Link to='/privacy-policy'>Privacy Policy</Link>
                  </li>
                </ul>
                <ul className='mb-40'>
                  <h3 className='title'>Affiliates</h3>
                  <li>
                    <a href='https://unitybrandsgroup.com/'>www.unitybrandsgroup.com</a>
                  </li>
                  <li>
                    <a href='https://www.thegrindstone.us/'>www.theGrindstone.us</a>
                  </li>
                  <li>
                    <a href='https://www.ellorafarms.net/'>www.ellorafarms.net</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col mb-40'>
              <div className='footer-widget'>
                <h3 className='title'>Follow us</h3>
                <ul>
                  <li>
                    <a href={social?.facebook}>Facebook</a>
                  </li>
                  <li>
                    <a href={social?.twitter}>Twitter</a>
                  </li>
                  <li>
                    <a href={social?.instagrame}>Instagram</a>
                  </li>
                  <li>
                    <a href={social?.linkedin}>Linkedin</a>
                  </li>
                  <li>
                    <a href={social?.youtube}>Youtube</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='footer-bottom-section section'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='copyright col-lg-7 col-12'>
              <p>© 2023 All Rights Reserved by Ellora Fine Foods Inc. Powered by Ellora Fine Foods Inc.</p>
            </div>
            <div className='payment-method col-lg-5 col-12'>
              <img src={payment} alt='payment method' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
