import { useState } from 'react';
import { useElloraContext } from '../../context/ElloraContext';
import { Accordion, Dropdown } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import noUserImage from '../../assets/images/account/no_user.png';
import { useAuthContext } from '../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';

function CustomToggle({ children, eventKey }: any) {
  const decoratedOnClick = useAccordionButton(eventKey, () => ({}));

  return (
    <a href='#' type='button' onClick={decoratedOnClick}>
      {children}
    </a>
  );
}

function Header({ showLogo }: { showLogo?: any }) {
  const navigate = useNavigate();
  const { isLogin, customer, logout } = useAuthContext();
  const { social, logo } = useElloraContext();
  const [navWidth, setNavWidth] = useState(0);
  const openNav = (width: number) => {
    setNavWidth(width);
  };
  const { categories, cart } = useElloraContext();
  const navigateToCart = () => {
    navigate('/cart');
  };
  return (
    <>
      <div className='main-wrapper'>
        <div className='icon-bar'>
          <a href={social?.facebook} className='facebook'>
            <i className='fa fa-facebook'></i>
          </a>
          <a href={social?.twitter} className='twitter'>
            <i className='fa fa-twitter'></i>
          </a>
          <a href={social?.youtube} className='youtube'>
            <i className='fa fa-youtube'></i>
          </a>
        </div>
      </div>
      <div className='header-section section bg-white'>
        <section className='top-strip'>
          <div className='container-fluid'>
            <div className='row justify-content-end'>
              <div className='col-1' style={{ paddingTop: '5px' }}>
                <div id='mySidenav' className='sidenav' style={{ width: `${navWidth}px` }}>
                  <a
                    href='#'
                    className='closebtn'
                    onClick={(e: any) => {
                      e.preventDefault();
                      openNav(0);
                    }}
                  >
                    &times;
                  </a>
                  <Accordion alwaysOpen>
                    <div className='card'>
                      <Link to='/'>HOME</Link>
                    </div>
                    <div className='card'>
                      <Link to='/about'>ABOUT</Link>
                    </div>
                    {categories?.categories &&
                      categories.categories.data.map((category: any) => {
                        return (
                          <div className='card' key={`category_home_${category.id}`}>
                            {category.attributes.sub_categories.data.length ? (
                              <CustomToggle eventKey={category.id}>
                                <span>
                                  <i className='fa fa-caret-right'></i>&nbsp;
                                </span>
                                {category.attributes.name}
                              </CustomToggle>
                            ) : (
                              <Link to={`/${category.attributes.slug}`} type='button'>
                                {category.attributes.name}
                              </Link>
                            )}
                            {category.attributes.sub_categories.data.length ? (
                              <Accordion.Collapse eventKey={category.id}>
                                <ul>
                                  {category.attributes.sub_categories.data.map(
                                    (subcategory: any) => {
                                      return (
                                        <li key={`subcategory_home_${subcategory.id}`}>
                                          <Link
                                            to={`/${category.attributes.slug}/${subcategory.attributes.slug}`}
                                          >
                                            {subcategory.attributes.name}
                                          </Link>
                                        </li>
                                      );
                                    },
                                  )}
                                </ul>
                              </Accordion.Collapse>
                            ) : null}
                          </div>
                        );
                      })}

                    <div className='card'>
                      <Link to='/contact'>CONTACT</Link>
                    </div>
                    <div className='card'>
                      <Link to='/wholesale'>WHOLESALE</Link>
                    </div>
                    <div className='card'>
                      <Link to='/blogs'>Blogs</Link>
                    </div>
                  </Accordion>
                </div>

                <span
                  onClick={() => openNav(250)}
                  style={{ fontSize: '30px', cursor: 'pointer', zIndex: '1', position: 'inherit' }}
                >
                  &#9776;
                </span>
              </div>
              <div className='col-8 row' style={{ padding: '3px' }}>
                <div className='col-3' style={{ padding: '3px' }}>
                  <div className='left-box'>
                    <Link to='/lot'>
                      <span>
                        <i className='fa fa-plus-circle' aria-hidden='true'></i>
                      </span>
                      Trace The Origin <br />
                      <span style={{ fontSize: '12px' }}>Trace The Origin</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-3 mobile'>
                <div className='header-action'>
                  {isLogin ? (
                    <Dropdown>
                      <Dropdown.Toggle id='account' className='account-button'>
                        <img src={noUserImage} />
                        <p>{customer.first_name}</p>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href='/dashboard'>Dashboard</Dropdown.Item>
                        <Dropdown.Item as='button' onClick={logout}>
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <div className='login-btn-wrapper'>
                      <Link to='/login' className='login-btn'>
                        Login
                      </Link>
                    </div>
                  )}
                  <div className='cart'>
                    <p onClick={navigateToCart}>
                      <i className='fa fa-shopping-cart'></i>
                      <span className='number'>{Object.keys(cart).length}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {showLogo ? (
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 col-12 text-center'>
                <div className='header-logo'>
                  <a href='/'>
                    <img src={logo} alt='ellora farms logo' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default Header;
