import { gql } from '@apollo/client';
import moment from 'moment';

export const getAllVideos = gql`
  {
    videos(pagination: { limit: 3 }) {
      data {
        id
        attributes {
          heading
          link
        }
      }
    }
  }
`;
export const getAllAwards = gql`
  {
    awards {
      data {
        id
        attributes {
          link
          name
          image {
            data {
              attributes {
                url
                formats
              }
            }
          }
        }
      }
    }
  }
`;
export const getAllBlogs = gql`
  {
    blogs(pagination: { limit: 3 }, sort: "date:desc") {
      data {
        id
        attributes {
          title
          short_description
          date
          slug
          createdAt
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
export const getAllCertificates = gql`
  {
    certificates {
      data {
        attributes {
          name
          link
          image {
            data {
              attributes {
                url
                formats
              }
            }
          }
        }
      }
    }
  }
`;
export const getAllSetting = gql`
  {
    socialMedia {
      data {
        attributes {
          phone_number {
            phone_number
          }
          phone_number2 {
            phone_number
          }
          social {
            address
            email
            website_link
            facebook
            twitter
            instagrame
            linkedin
            youtube
            lat
            long
          }
          banner_image {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const getProductsById = (ids: string) => gql`
{
products(filters:{id:{in:[${ids}]}}){
    data{
      id
      attributes{
        name
        slug
        price
        mrp
        quantity
        weight
        cross_sales{
          data{
            id
            attributes{
              name
              slug
              price
              mrp
              quantity
              categories{
                data{
                  attributes{
                    name
                    slug
                  }
                }
              }
              sub_categories{
                data{
                  attributes{
                    name
                    slug
                  }
                }
              }
            image{
              data{
                attributes{
                    url
                }
              }
            }
            }
          }
        }
        categories{
            data{
              attributes{
                name
                slug
              }
            }
          }
          sub_categories{
            data{
              attributes{
                name
                slug
              }
            }
          }
        image{
          data{
            attributes{
                url
            }
          }
        }
      }
    }
  }
}
`;
export const getCouponCode = gql`
query GetCoupon($code:String){
coupons(filters:{code:{eq:$code}, end_date:{gte:"${moment().format('YYYY-MM-DD')}"}}){
    data{
      id
      attributes{
        amount
        start_date
        end_date
      }
    }
  }
}
`;

export const ME = gql`
  {
    me {
      id
      role {
        id
        name
        type
      }
    }
  }
`;

export const HomePage = gql`
  {
    home {
      data {
        attributes {
          moveable_text_title
          moveable_text_subtitle
          number_of_product_in_slider
          global_award_bg {
            data {
              id
              attributes {
                url
              }
            }
          }
          health_bg {
            data {
              id
              attributes {
                url
              }
            }
          }
          global_gold_award_bg {
            data {
              id
              attributes {
                url
              }
            }
          }
          subscribe_section_bg {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const getTrace = gql`
query GetTrace($id:ID!){
  traceOrigin(id:$id){
    data{
      id
      attributes{
        image{
          data{
            id
            attributes{
              url
              formats
            }
          }
        }
        introduction
        lot_number
        bottling{
          product
          production_date
          best_before
          factory
        }
        farmer{
          description
          details{
            code
            area
            altitude
            lat
            long
            export_date
            registry_number
          }
        }
        origin{
          production_olive_mills
          area
          image{
            data{
              id
              attributes{
                url
                formats
              }
            }
          }
        }
        packaging{
          image{
            data{
              id
              attributes{
                url
                formats
              }
            }
          }
          content
        }
        laboratory{
          test{
            test
            result
            max
          }
          image{
            data{
              id
              attributes{
                url
                formats
              }
            }
          }
        }
          origin_grove{
            image{
              data{
                id
                attributes{
                  url
                  formats
                }
              }
            }
            content
          }
            pdf{
              files{
                data{
                  id
                  attributes{
                    url
                    formats
                  }
                }
              }
            },
            crop_images{
              files{
                data{
                  id
                  attributes{
                    url
                    formats
                  }
                }
              }
            }
      }
    }
  }
}
`