import React, {lazy} from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import '../assets/css/app.min.css';
import './admin.css';
import 'react-widgets/styles.css';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { Suspense, useEffect, useState } from 'react';
import { ADMIN_URL } from './admin-config';
import { useElloraContext } from '../context/ElloraContext';
import { isEmpty } from 'lodash';

function Admin() {
  const navigate = useNavigate();
  const admin_login = sessionStorage.getItem('admin_login') || 'false';
  const { admin } = useElloraContext();
  const [checking, setChecking] = useState<boolean>(true);
  useEffect(() => {
    if (admin_login == 'true' && !isEmpty(admin) && sessionStorage.getItem('admin') != null) {
      setChecking(false);
    } else {
      navigate(`${ADMIN_URL}/login`);
    }
  });
  if (checking) return <>Loading...</>;
  return (
    <>
      <div id='layout-wrapper'>
        <Header />
        <Sidebar />
        <div className='main-content'>
          <div className='page-content'>
            <div className='container-fluid'>
              <Suspense fallback="Loading...">
                <Routes>
                  <Route path='/' Component={lazy(()=>import("./components/Dashboard"))} />
                  <Route path='/inquiries/*' Component={lazy(()=>import("./components/Inquiry/Index"))} />
                  <Route path='/orders/*' Component={lazy(()=>import("./components/Order/Index"))} />
                  <Route path='/categories/*' Component={lazy(()=>import("./components/Category/Index"))} />
                  <Route path='/subcategories/*' Component={lazy(()=>import("./components/SubCategory/Index"))} />
                  <Route path='/products/*' Component={lazy(()=>import("./components/Product/Index"))} />
                  <Route path='/herosliders/*' Component={lazy(()=>import("./components/HomeSlider/Index"))} />
                  <Route path='/subscribers/*' Component={lazy(()=>import("./components/Subscribers/Index"))} />
                  <Route path='/certificates/*' Component={lazy(()=>import("./components/Certificate/Index"))} />
                  <Route path='/awards/*' Component={lazy(()=>import("./components/Award/Index"))} />
                  <Route path='/blogs/*' Component={lazy(()=>import("./components/Blog/Index"))} />
                  <Route path='/rangesliders/*' Component={lazy(()=>import("./components/Range/Index"))} />
                  <Route path='/videos/*' Component={lazy(()=>import("./components/Video/Index"))} />
                  <Route path='/ratings/*' Component={lazy(()=>import("./components/Rating/Index"))} />
                  <Route path='/coupons/*' Component={lazy(()=>import("./components/Coupon/Index"))} />
                  <Route path='/settings/*' Component={lazy(()=>import("./components/Setting/index"))} />
                  <Route path='/cmspages/*' Component={lazy(()=>import("./components/Page/Index"))} />
                  <Route path='/media/*' Component={lazy(()=>import("./components/Media/Index"))} />
                  <Route path='/trace-origin/*' Component={lazy(()=>import("./components/Trace/Index"))} />
                  <Route path='/customers/*' Component={lazy(()=>import("./components/User/Index"))} />
                </Routes>
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Admin;
