import { useEffect, useState } from 'react';
import Logo from '../assets/images/logo.png';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LoginUser } from '../queries/mutation';
import { ME } from '../queries/query';
import { useNavigate } from 'react-router-dom';
import { ADMIN_URL } from './admin-config';
import { toast } from 'react-toastify';
import { useElloraContext } from '../context/ElloraContext';
function Login() {
  const navigate = useNavigate();
  useEffect(() => {
    document.body.classList.add('authentication-bg');
  }, []);
  const [loginForm, setLoginForm] = useState<any>({
    email: '',
    password: '',
  });
  const { setAdmin } = useElloraContext();
  const [loginError, setLoginError] = useState<string>('');
  const [login] = useMutation(LoginUser);
  const [me] = useLazyQuery(ME);
  const SubmitLoginForm = (e: any) => {
    e.preventDefault();
    login({
      variables: loginForm,
    })
      .then((user) => {
        sessionStorage.setItem('admin_login', 'true');
        sessionStorage.setItem('access_token', user.data.login.jwt);
        sessionStorage.setItem('admin', JSON.stringify(user.data?.login.user));
        setAdmin(user.data?.login.user);
        me()
          .then((ru) => {
            if (ru.data.me.role.type == 'admin') {
              navigate(`${ADMIN_URL}/`);
            } else {
              unauthenticated();
            }
          })
          .catch((err) => {
            toast.error(err.message);
            unauthenticated();
          });
      })
      .catch((_err) => {
        unauthenticated();
      });
  };
  const unauthenticated = () => {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('admin_login');
    setLoginError('Username and password not match');
  };
  return (
    <div className='account-pages my-5 pt-sm-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
              <a href='#' className='mb-5 d-block auth-logo'>
                <img src={Logo} alt='' height='100' className='logo logo-dark' />
                <img src={Logo} alt='' height='100' className='logo logo-light' />
              </a>
            </div>
          </div>
        </div>
        <div className='row align-items-center justify-content-center'>
          <div className='col-md-8 col-lg-6 col-xl-5'>
            <div className='card'>
              <div className='card-body p-4'>
                <div className='text-center mt-2'>
                  <h3 className='text-danger'>{loginError}</h3>
                </div>
                <div className='p-2 mt-4'>
                  <form onSubmit={SubmitLoginForm}>
                    <div className='form-group'>
                      <label htmlFor='username'>E-mail</label>
                      <input
                        type='email'
                        className='form-control'
                        id='E-mail'
                        placeholder='Enter E-mail'
                        onChange={(e: any) => setLoginForm({ ...loginForm, email: e.target.value })}
                        required
                      />
                    </div>

                    <div className='form-group'>
                      <label htmlFor='userpassword'>Password</label>
                      <input
                        type='password'
                        className='form-control'
                        id='userpassword'
                        placeholder='Enter password'
                        onChange={(e: any) =>
                          setLoginForm({ ...loginForm, password: e.target.value })
                        }
                        required
                      />
                    </div>

                    <div className='mt-3 text-right'>
                      <button
                        className='btn btn-primary w-sm waves-effect waves-light'
                        type='submit'
                        name='submit'
                      >
                        Log In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className='mt-5 text-center'>
              <p>© 2023 All Rights Reserved by Ellora Fine Foods Inc. Powered by Ellora Fine Foods Inc.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
