import React, { Suspense, lazy } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './front.css';
import './productdetails.css';
import AuthContextProvider from '../context/AuthContext';
import { useEffect, useState } from 'react';
import CookieConsent from 'react-cookie-consent';

function Front() {
  const { pathname } = useLocation();
  const [showLogAndFooter, setShowLogAndFooter] = useState(
    pathname && !pathname.includes('dashboard'),
  );
  useEffect(() => {
    setShowLogAndFooter(pathname && !pathname.includes('dashboard'));
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <AuthContextProvider>
        <Header showLogo={showLogAndFooter} />
        <Suspense fallback="Loading...">
          <Routes>
            <Route path='/' Component={lazy(() => import('./components/HomePage'))} />
            <Route path='/login' Component={lazy(() => import("./components/Login"))} />
            <Route path='/register' Component={lazy(() => import("./components/Register"))} />
            <Route path='/:category' Component={lazy(() => import("./components/Category"))} />
            <Route path='/:category/:subcategory' Component={lazy(() => import("./components/ProductList"))} />
            <Route path='/:category/:subcategory/:product' Component={lazy(() => import("./components/ProductDetails"))} />
            <Route path='/cart' Component={lazy(() => import("./components/Cart"))} />
            <Route path='/checkout' Component={lazy(() => import("./components/Checkout"))} />
            <Route path='/contact' Component={lazy(() => import("./components/Contact"))} />
            <Route path='/wholesale' Component={lazy(() => import("./components/Wholesale"))} />
            <Route path='/about' Component={lazy(() => import("./components/About"))} />
            <Route path='/blog-detail/:blog' Component={lazy(() => import("./components/BlogDetails"))} />
            <Route path='/order/:order/:payment/complete' Component={lazy(() => import("./components/Thanks"))} />
            <Route path='/dashboard' Component={lazy(() => import("./components/Dashboard"))}>
              <Route path='' Component={lazy(() => import("./components/UserPanel/Index"))} />
              <Route path='orders' Component={lazy(() => import("./components/UserPanel/Orders"))} />
              <Route path='account' Component={lazy(() => import("./components/UserPanel/Account"))} />
              <Route path='invoice/:id' Component={lazy(() => import("./components/UserPanel/Invoice"))} />
            </Route>
            <Route path='/features' Component={lazy(() => import("./components/Features"))} />
            <Route path='/features/:page' Component={lazy(() => import("./components/Features"))} />
            <Route path='/health-benefits' Component={lazy(() => import("./components/Features"))} />
            <Route path='/health-benefits/:page' Component={lazy(() => import("./components/Features"))} />
            <Route path='/privacy-policy' Component={lazy(() => import("./components/PrivacyPolicy"))} />
            <Route path='/blogs' Component={lazy(() => import("./components/BlogList"))} />
            <Route path='/forgot-password' Component={lazy(() => import("./components/ForgotPassword"))} />
            <Route path='/reset-password' Component={lazy(() => import("./components/ResetPassword"))} />
            <Route path='/lot' Component={lazy(() => import("./components/TraceOrigin"))} />
            <Route path='/videos' Component={lazy(() => import("./components/VideoList"))} />
            <Route path='/confirm-email' Component={lazy(() => import("./components/EmailVerified"))} />
          </Routes>
          {showLogAndFooter ? <Footer /> : null}
          {
            !pathname.includes("admin") ?
              <CookieConsent
                location="bottom"
                buttonText="OK"
                cookieName="ellorafarms-cookie-consent"
                style={{ background: "rgb(34 34 34)" }}
                buttonStyle={{ background: "rgb(243 200 13)", color:"#000", fontSize: "15px", borderRadius:"10px" }}
                expires={150}
              >
                This website uses cookies to offer you the best navigation experience.
              </CookieConsent>
              : null
          }
        </Suspense>
      </AuthContextProvider>
    </>
  );
}

export default Front;
