import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type AuthContextType = {
  isLogin: any;
  setIsLogin: any;
  customer: any;
  setCustomer: any;
  accessToken: any;
  setAccessToken: any;
  logout: any;
};
type ContextType = {
  children: any;
};
const AuthContext = createContext<AuthContextType>({} as AuthContextType);
export const useAuthContext = () => useContext(AuthContext);
function AuthContextProvider({ children }: ContextType) {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState<boolean | undefined>(
    sessionStorage.getItem('isCustomerLogin') == 'true',
  );
  const [customer, setCustomer] = useState<any>(
    JSON.parse(sessionStorage.getItem('customer') || '{}'),
  );
  const [accessToken, setAccessToken] = useState<any>(sessionStorage.getItem('access_token'));
  const logout = () => {
    sessionStorage.clear();
    setCustomer('');
    setIsLogin(false);
    navigate('/');
  };
  return (
    <AuthContext.Provider
      value={{ isLogin, setIsLogin, customer, setCustomer, accessToken, setAccessToken, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
