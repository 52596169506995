import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import { ADMIN_URL } from '../admin-config';
function Sidebar() {
  const { pathname } = useLocation();
  const menus = [
    { title: 'Dashboard', url: '', icon: 'uil-home-alt' },
    { title: 'Manage Inquiry', url: 'inquiries', icon: 'fa fa-arrow-circle-right' },
    { title: 'Manage Orders', url: 'orders', icon: 'fa fa-arrow-circle-right' },
    { title: 'Manage Reviews', url: 'ratings', icon: 'fa fa-arrow-circle-right' },
    { title: 'Manage Products', url: 'products', icon: 'fa fa-arrow-circle-right' },
    { title: 'Manage Media', url: 'media', icon: 'fa fa-arrow-circle-right' },
    { title: 'Manage Pages', url: 'cmsPages', icon: 'fa fa-arrow-circle-right' },
    { title: 'Manage Categories', url: 'categories', icon: 'fa fa-arrow-circle-right' },
    { title: 'Manage Sub-Categories', url: 'subcategories', icon: 'fa fa-arrow-circle-right' },
    { title: 'Manage Carousel', url: 'herosliders', icon: 'fa fa-arrow-circle-right' },
    { title: 'Manage Range Sliders', url: 'rangesliders', icon: 'fa fa-arrow-circle-right' },
    { title: 'Manage Subscribers', url: 'subscribers', icon: 'fa fa-arrow-circle-right' },
    { title: 'Manage Certificates', url: 'certificates', icon: 'fa fa-arrow-circle-right' },
    { title: 'Manage Awards', url: 'awards', icon: 'fa fa-arrow-circle-right' },
    { title: 'Manage Blogs', url: 'blogs', icon: 'fa fa-arrow-circle-right' },
    { title: 'Manage Videos', url: 'videos', icon: 'fa fa-arrow-circle-right' },    
    { title: 'Manage Coupons', url: 'coupons', icon: 'fa fa-arrow-circle-right' },
    { title: 'Manage Trace', url: 'trace-origin', icon: 'fa fa-arrow-circle-right' },
    { title: 'Manage Customers', url: 'customers', icon: 'fa fa-arrow-circle-right' },
    { title: 'Manage Settings', url: 'settings', icon: 'fa fa-arrow-circle-right' },
  ];
  return (
    <div className='vertical-menu'>
      <div className='navbar-brand-box'>
        <a href='/' className='logo logo-dark'>
          <span className='logo-sm'>
            <img src={Logo} alt='' height='44' />
          </span>
          <span className='logo-lg'>
            <img src={Logo} alt='' height='44' />
          </span>
        </a>

        <a href='/' className='logo logo-light'>
          <span className='logo-sm'>
            <img src={Logo} alt='' height='44' />
          </span>
          <span className='logo-lg'>
            <img src={Logo} alt='' height='44' />
          </span>
        </a>
      </div>

      <div data-simplebar className='sidebar-menu-scroll'>
        <div id='sidebar-menu'>
          <ul className='metismenu list-unstyled' id='side-menu'>
            {menus.map((menu: any, index: number) => (
              <li
                className={pathname === `${ADMIN_URL}/${menu.url}` ? 'mm-active' : ''}
                key={`main_menu_${index}`}
              >
                <Link to={`${ADMIN_URL}/${menu.url}`}>
                  <i className={menu.icon} aria-hidden='true'></i>
                  <span>{menu.title}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
